import React, { useState } from "react";
import tw from "twin.macro";
import emailjs from "@emailjs/browser";

import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import EmailIllustrationSrc from "images/email-illustration (1).svg";
import SubheadingText from "components/UI/SubheadingText";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-5 md:py-10`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)((props) => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft
    ? tw`md:mr-12 lg:mr-16 md:order-first`
    : tw`md:ml-12 lg:ml-16 md:order-last`,
]);

const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(
  SubheadingBase
)`text-center md:text-left text-primary-100`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`;
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 border-[#161616] focus:outline-none font-medium transition duration-300 hocus:border-primary-100 placeholder-black`;
const Textarea = styled(Input).attrs({ as: "textarea" })`
  ${tw`h-24`}
`;

const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`;

export default ({
  subheading = "Contact Us",
  heading = (
    <>
      Feel free to <span tw="text-primary-100">get in touch</span>
      <wbr /> with us.
    </>
  ),
  description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  submitButtonText = "Send Message",
  formAction = "#",
  formMethod = "get",
  textOnLeft = true,
}) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const [errors, setErrors] = useState({
    name: "This field is required",
    email: "This field is required",
  });

  const emailRegx = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const regName = /^[a-zA-Z ]*$/;

  const hasErrors = () => {
    if (errors.name || errors.email) {
      return true;
    }
    return false;
  };

  const inputChangeHandler = (inputType, value) => {
    switch (inputType) {
      case "name":
        if (!regName.test(value)) {
          setErrors({ ...errors, ["name"]: "Enter a valid name" });
        } else {
          setErrors({ ...errors, ["name"]: "" });
        }

        setName(value);
        break;

      case "email":
        if (!emailRegx.test(value)) {
          setErrors({ ...errors, ["email"]: "Enter a valid email address" });
        } else {
          setErrors({ ...errors, ["email"]: "" });
        }
        setEmail(value);
        break;

      case "subject":
        setSubject(value);
        break;

      case "message":
        setMessage(value);
        break;

      default:
        break;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsFormSubmitted(true);

    if (!hasErrors()) {
      setIsLoading(true);

      const templateParams = {
        name,
        email,
        subject,
        message,
      };

      // Replace these with your actual EmailJS service, template, and user IDs
      const emailServiceId = "service_vytgiuw";
      const emailTemplateId = "template_pyqx18n";
      const emailUserId = "UqH_uL697uCWPTPnW";

      emailjs
        .send(emailServiceId, emailTemplateId, templateParams, emailUserId)
        .then((response) => {
          setSuccessMessage("Thanks for contacting us");
          setName("");
          setEmail("");
          setSubject("");
          setMessage("");
        })
        .catch((err) => {
          console.error("Failed to send email:", err);
          setSuccessMessage(
            "Oops! Something went wrong. Please try again later."
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc={EmailIllustrationSrc} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && <SubheadingText>{subheading}</SubheadingText>}
            <Heading>{heading}</Heading>
            {/* {description && <Description>{description}</Description>} */}
            <Form
              action={formAction}
              method={formMethod}
              onSubmit={handleSubmit}
            >
              <Input
                type="email"
                name="email"
                placeholder="Your Email Address"
                value={email}
                onChange={(e) => {
                  inputChangeHandler("email", e.target.value);
                }}
              />

              <Input
                type="text"
                name="name"
                placeholder="Full Name"
                value={name}
                onChange={(e) => {
                  inputChangeHandler("name", e.target.value);
                }}
              />

              <Input
                type="text"
                name="subject"
                placeholder="Subject"
                value={subject}
                onChange={(e) => {
                  inputChangeHandler("subject", e.target.value);
                }}
              />

              <Textarea
                name="message"
                placeholder="Your Message Here"
                value={message}
                onChange={(e) => {
                  inputChangeHandler("message", e.target.value);
                }}
              />

              <SubmitButton
                type="submit"
                tw="bg-primary-100 hover:bg-black"
                disabled={isLoading || isFormSubmitted}
              >
                {isLoading ? "Sending..." : submitButtonText}
              </SubmitButton>

              {isFormSubmitted && !isLoading && <p>{successMessage}</p>}
            </Form>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
