import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading } from "components/misc/Headings.js";

import defaultCardImage from "../../images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "../../images/svg-decorator-blob-3.svg";

import SupportIconImage from "../../images/support-icon.svg";
import ShieldIconImage from "../../images/shield-icon.svg";
import CustomizeIconImage from "../../images/customize-icon.svg";
import FastIconImage from "../../images/fast-icon.svg";
import ReliableIconImage from "../../images/reliable-icon.svg";
import SimpleIconImage from "../../images/simple-icon.svg";
import DataScienceImage from "../../images/icons/data-science 1.svg";
import MobileImage from "../../images/icons/mobile-app.svg";
import WebImage from "../../images/icons/web.svg";
import UIUXImage from "../../images/icons/prototype (1) 1.svg";
import CRMImage from "../../images/icons/crm.svg";
import DesktopImage from "../../images/icons/desktop-computer (1) 1.svg";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-10`}
`;
const Heading = tw(SectionHeading)`w-[90%] md:w-[60%] my-6 `;

const ServicesContainer = tw.div`w-full flex flex-wrap justify-evenly flex-grow`;

const Column = styled.div`
  ${tw`w-[90%] md:w-[40%] lg:w-[30%] my-2 `}
`;

const Card = styled.div`
  ${tw`flex flex-col w-full shadow-xl shadow-gray-500 border-4 border-gray-100  items-center px-6 py-10  rounded-lg h-[100%]`}
  .imageContainer {
    ${tw` text-white text-center rounded-full p-4 flex-shrink-0 relative `}
    img {
      ${tw`w-12 h-12`}
    }
  }

  .textContainer {
    ${tw`mt-6 text-center`}
  }

  .title {
    ${tw`mt-2 font-bold text-xl leading-none text-primary-100`}
  }

  .description {
    ${tw`mt-3 font-semibold text-white text-sm leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default () => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const cards = [
    {
      backgroundColor: "white",
      color: "black",
      imageSrc: WebImage,
      imageBackground: "black",
      title: "Web App Development",
      description:
        "We believe that our client's needs are tailored according to their business. To satisfy those needs, we provide from strategizing to implementation of interactive web development services. These top-notch services are customized for round the globe clients to meet their ROI expectations.",
    },
    {
      backgroundColor: "white",
      color: "black",

      imageSrc: MobileImage,
      imageBackground: "white",

      title: "Mobile App Development",
      description:
        "Our developers use the latest technologies to create the mobile app solutions for our client's custom needs. Our experts use best tools and practices to build high performance apps with easy use and pertinence. We deliver both native and cross platform mobile apps.",
    },
    {
      backgroundColor: "white",
      color: "black",
      imageBackground: "black",

      imageSrc: DataScienceImage,
      title: "Data Science",
      description:
        "We provide the service for your data. Our data scientists are at service to provide you with the best analytics techniques and deep learning to provide you with gleaned information. With our scientist you will be satisfied with the provision of data with quality.",
    },
    {
      backgroundColor: "white",
      color: "black",
      imageBackground: "black",

      imageSrc: UIUXImage,
      title: "UI/UX",
      description:
        "We provide our UI/UX services based on mapping of our client's needs. We offer tailored designs for our client's expectations and their business challenges. Our engineers and developers strive to give services perfect from experience and design.",
    },
    {
      backgroundColor: "white",
      color: "black",
      imageBackground: "white",

      imageSrc: CRMImage,
      title: "CRM development",
      description:
        "We excel in CRM development, providing expert guidance to maximize the potential of our customized solutions. Our advanced infrastructure enables seamless connections and deep understanding of client needs, delivering tailored solutions for complex CRM challenges.",
    },
    {
      backgroundColor: "white",
      imageBackground: "black",

      color: "black",
      imageSrc: DesktopImage,
      title: "Desktop Software Solutions",
      description:
        "Our team consistently strives for providing the best software solutions for your desktops. Whether your project is existing or new, we are your one-stop for every desktop software solution that you can imagine.",
    },
  ];

  return (
    <Container>
      <ThreeColumnContainer>
        <Heading>
          Our Mission Is To Make Your{" "}
          <span tw="text-primary-100">Business</span> Better Through
          Technologies
        </Heading>
        <ServicesContainer>
          {cards.map((card, i) => (
            <Column key={i}>
              <Card style={{ backgroundColor: card.backgroundColor }}>
                <span
                  className="imageContainer"
                  // style={{ backgroundColor: card.imageBackground }}
                >
                  <img src={card.imageSrc || defaultCardImage} alt="" />
                </span>
                <span className="textContainer">
                  <span className="title">{card.title || "Fully Secure"}</span>
                  <p style={{ color: card.color }} className="description">
                    {card.description ||
                      "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud. Sic Semper Tyrannis. Neoas Calie artel."}
                  </p>
                </span>
              </Card>
            </Column>
          ))}
        </ServicesContainer>
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
