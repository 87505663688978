import React from "react";
import tw from "twin.macro";

import { Subheading as SubheadingBase } from "components/misc/Headings.js";

const SubheadingContainer = tw.div`flex md:justify-start justify-center`;

const Subheading = tw(
  SubheadingBase
)`text-center text-2xl text-primary-100 md:text-left uppercase`;

const BorderBottom = tw.div`w-[50%] border-b-2 border-black`;

const SubheadingText = ({ children, justify }) => {
  return (
    <SubheadingContainer style={{ justifyContent: justify ? justify : "" }}>
      <Subheading>
        {children} <br /> <BorderBottom />
      </Subheading>
    </SubheadingContainer>
  );
};

export default SubheadingText;
