import React from "react";

import tw from "twin.macro";
import styled from "styled-components";

import { ReactComponent as LinkedinIcon } from "../../images/linkedin-icon.svg";
import { ReactComponent as TwitterIcon } from "../../images/twitter-icon.svg";
import { ReactComponent as InstagramIcon } from "../../images/insta.svg";
import { ReactComponent as FacebookIcon } from "../../images/facebook-icon.svg";

const SocialLinksContainer = tw.div`flex justify-center gap-5`;
const SocialLink = styled.a`
  ${tw`cursor-pointer bg-primary-100 p-2 lg:p-3 rounded-3xl hover:bg-black transition-colors duration-500 `}
  svg {
    ${tw`w-5 h-5 md:w-3 md:h-3 lg:w-5 lg:h-5 `}
  }
`;

const SocialLinks = () => {
  return (
    <SocialLinksContainer>
      <SocialLink href="https://www.linkedin.com/company/74970607/admin/feed/posts/">
        <LinkedinIcon />
      </SocialLink>
      <SocialLink href="https://www.facebook.com/sevenstacksltd">
        <FacebookIcon />
      </SocialLink>
      <SocialLink href="https://www.instagram.com/sevenstacksltd/">
        <InstagramIcon />
      </SocialLink>
    </SocialLinksContainer>
  );
};

export default SocialLinks;
