import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { Container as ContainerBase } from "components/misc/Layouts.js";
import logo from "../../images/whitelogo.svg";

import { NavLink } from "react-router-dom";

import SocialLinks from "./SocialLinks";

const Container = tw(ContainerBase)`bg-[#3D3D3D] text-gray-100 -mx-8`;

const Content = tw.div`max-w-screen-xl mx-auto py-5 lg:py-10`;

const Row = tw.div`flex flex-col px-8 justify-center md:justify-between md:flex-row `;

const LogoContainer = tw.div`flex py-10 md:py-0 items-center justify-center md:justify-start`;
const CompanyLoge = tw.img`w-[200px] md:w-[300px] lg:w-[400px] `;

const LinksContainer = tw.div`font-medium flex flex-col items-center md:items-start w-full md:w-[150px] lg:w-[250px] `;
const Link = tw(
  NavLink
)`border-b-2 border-transparent hocus:text-gray-300 hocus:border-gray-300 pb-1 transition duration-300`;

const ThirdSection = tw.div`w-full items-center md:items-start md:w-[150px] lg:w-[350px]`;
const AddressSection = tw.div`text-sm lg:text-sm flex flex-col  items-center md:items-start`;
const SectionTitle = tw.h2`font-bold text-lg py-5`;
const SocialSection = tw.div`flex flex-col items-center justify-center md:items-start py-2 my-4`;
const AddressLine = tw.p`sm:text-sm md:text-base`;

// const CopyrightText = tw.p`text-center   font-medium tracking-wide text-sm text-gray-600 bg-green-100 w-full mt-8 -mx-8 -mb-8 py-10`;
const CopyrightText = tw.p`bg-gray-900 text-gray-100 -mx-8 -mb-8 p-8 flex items-center justify-center bg-primary-100 text-xs md:text-sm`;
export default () => {
  return (
    <>
      <Container>
        <Content>
          <Row>
            <LogoContainer>
              <CompanyLoge src={logo} alt="logo" />
            </LogoContainer>
            <LinksContainer>
              <SectionTitle>Pages</SectionTitle>

              <Link exact to="/">
                Home
              </Link>
              <Link to="/services">Services</Link>
              <Link to="/about">About Us</Link>
              {/* <Link to="#">Case Studies</Link> */}
              <Link to="/contact-us">Contact Us</Link>
            </LinksContainer>
            <ThirdSection>
              <AddressSection>
                <SectionTitle>Find Us</SectionTitle>
                <AddressLine>
                  80-F, Architects Engineers Housing Society,
                </AddressLine>
                <AddressLine>Lahore, PK, 54000</AddressLine>
              </AddressSection>
              <SocialSection>
                {/* <SectionTitle>Socials</SectionTitle> */}
                <SocialLinks />
              </SocialSection>
            </ThirdSection>
          </Row>
        </Content>
      </Container>
      <CopyrightText>
        &copy; Copyright 2023, Seven Stacks. All Rights Reserved.
      </CopyrightText>
    </>
  );
};
