import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithInput.js";
import Features from "components/features/ThreeColWithSideImage.js";
import MainFeature2 from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";

import Footer from "components/footers/FooterWithAddress";
import prototypeIllustrationImageSrc from "images/prototype-illustration-new.svg";

import { ReactComponent as BriefcaseIcon } from "images/design.svg";
import { ReactComponent as MoneyIcon } from "images/arrow-left-3-icon.svg";
import FeatureStats from "components/features/ThreeColCenteredStatsPrimaryBackground.js";
import SubscribeNewsLetterForm from "components/forms/SimpleSubscribeNewsletter.js";
import Testimonial from "components/landing page/testimonial";

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-100`;
  const HighlightedText = tw.span`text-primary-100`;

  return (
    <AnimationRevealPage>
      <Hero roundedHeaderButton={true} />
      <Features
        subheading={<Subheading>Features</Subheading>}
        heading={
          <>
            We have Amazing <HighlightedText>Service</HighlightedText>
          </>
        }
      />

      {/* <FeatureStats /> */}

      <Testimonial />
      <MainFeature2
        subheading={<Subheading>VALUES</Subheading>}
        heading={
          <>
            What we <HighlightedText>Value</HighlightedText>
          </>
        }
        imageSrc={prototypeIllustrationImageSrc}
        description="We value our integration that lies in abiding by our principle of delivering out of the box software solutions that exceed the expectations of our clients. our secret to success lies in following processes:"
        showDecoratorBlob={false}
        features={[
          {
            Icon: BriefcaseIcon,

            title: "Requirements Gathering",
            // description: 'We have the best professional marketing people across the globe just to work with you.',
            iconContainerCss: tw`bg-teal-800 text-teal-800`,
          },
          {
            Icon: MoneyIcon,
            title: "Software Design",
            // description: 'We promise to offer you the best rate we can - at par with the industry standard.',
            iconContainerCss: tw`bg-red-300 text-red-800`,
          },
          {
            Icon: MoneyIcon,
            title: "Development",
            // description: 'We promise to offer you the best rate we can - at par with the industry standard.',
            iconContainerCss: tw`bg-teal-800 text-teal-800`,
          },
          {
            Icon: MoneyIcon,
            title: "Maintenance and Support",
            // description: 'We promise to offer you the best rate we can - at par with the industry standard.',

            iconContainerCss: tw`bg-red-300 text-red-800`,
          },
          // {
          //   Icon: BriefcaseIcon,
          //   title: "Develop",
          //   description: "We have the best professional marketing people across the globe just to work with you.",
          //   iconContainerCss: tw`bg-teal-300 text-teal-800`
          // },
          // {
          //   Icon: MoneyIcon,
          //   title: "Deploy",
          //   description: "We promise to offer you the best rate we can - at par with the industry standard.",
          //   iconContainerCss: tw`bg-red-300 text-red-800`
          // }
        ]}
      />
      <SubscribeNewsLetterForm />
      <Footer />
    </AnimationRevealPage>
  );
};
