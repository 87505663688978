import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FooterWithAddress";
import MainFeature1 from "components/features/TwoColWithButton.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
import Features from "components/features/ThreeColSimple.js";
// import Features from "components/features/ThreeColWithSideImage.js";
import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomerLoveIconImage from "images/simple-icon.svg";

import AboutImage from "./../images/about/about us.svg";
import VisionImage from "./../images/about/vision (2).svg";

const Subheading = tw.span`justify-center`;
export default () => {
  return (
    <AnimationRevealPage>
      <Header roundedHeaderButton={true} />
      <MainFeature1
        subheading={"About Seven Stacks"}
        subHeadingStyle="justify-start"
        heading="We are a service provider software house"
        buttonRounded={false}
        primaryButtonText="See Portfolio"
        description="We are creating a milestone in the world of technology with our experts. Our clients get assured services by our technology experts and we get A+ client feedback. We have trained and developed technology personnel who will quench your thirst for digital solutions."
        imageSrc={AboutImage}
      />
      <MainFeature1
        subheading={"Our Vision"}
        subHeadingStyle="justify-start"
        heading="Empowering Clients with Innovative Software Solutions"
        buttonRounded={false}
        primaryButtonText="Contact Us"
        imageSrc={VisionImage}
        textOnLeft={false}
        description="Our vision is to bring revolution in the field of software services and meet the expectations of the clients up to the mark by offering them services they were looking up to a level. We are aware that the more our clients grow, the more we grow. So we are committed to give our best to our clients."
      />
      <Features
        subheading={<Subheading>Our Values</Subheading>}
        justify="center"
        heading="We follow these"
        description="Our core value is integration, as we adhere to our principle of providing innovative software solutions that surpass our clients' expectations."
        cards={[
          {
            imageSrc: SupportIconImage,
            title: "Round the clock",
            // description: 'Lorem ipsum donor amet siti ceali placeholder text alipiscing elit sed do eiusmod temport',
          },
          {
            imageSrc: ShieldIconImage,
            title: "Strong Teams",
            // description: 'Lorem ipsum donor amet siti ceali placeholder text alipiscing elit sed do eiusmod temport',
          },
          {
            imageSrc: CustomerLoveIconImage,
            title: "Customer Satisfaction",
            // description: 'Lorem ipsum donor amet siti ceali placeholder text alipiscing elit sed do eiusmod temport',
          },
        ]}
        linkText=""
      />
      {/* <TeamCardGrid subheading={<Subheading>Our Team</Subheading>} /> */}
      <Footer />
    </AnimationRevealPage>
  );
};
