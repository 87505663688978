import React from "react";
import tw from "twin.macro";
import { ReactComponent as EmailNewsletterIconBase } from "../../images/email-newsletter-icon.svg";
import { Container as ContainerBase } from "components/misc/Layouts.js";
import { SectionHeading } from "components/misc/Headings.js";
import { PrimaryButton } from "components/misc/Buttons.js";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Container = tw(ContainerBase)`bg-black -mx-8`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const Row = tw.div`flex flex-col items-center justify-center flex-col lg:flex-row px-8 lg:gap-8`;
const TextColumn = tw.div`flex items-center flex-col sm:flex-row`;

const EmailNewsletterIcon = tw(
  EmailNewsletterIconBase
)`w-16 h-16 text-green-500`;
const HeadingInfoContainer = tw.div` mt-6 sm:mt-0`;
const Heading = tw(SectionHeading)`text-gray-100 sm:text-left leading-none`;
const Description = tw.p`text-gray-500 font-medium text-sm max-w-sm mt-2 sm:mt-1 text-center sm:text-left`;

const FormColumn = tw.div`mt-12 lg:mt-0 w-full sm:w-auto flex justify-center`;
const Button = tw(
  Link
)`sm:mt-0 align-middle items-center py-4 rounded-md px-6 bg-primary-100 text-gray-100 hocus:bg-black hocus:text-gray-300 border border-green-500 hocus:border-white transition duration-500`;

export default () => {
  return (
    <Container>
      <Content>
        <Row className="">
          <TextColumn>
            <HeadingInfoContainer>
              <Heading>Contact Us</Heading>
              {/* <Description>Subscribe now to get our latest blog posts.</Description> */}
            </HeadingInfoContainer>
          </TextColumn>
          <FormColumn>
            <Button type="submit" to="/contact-us">
              Contact Now
            </Button>
          </FormColumn>
        </Row>
      </Content>
    </Container>
  );
};
