import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";

import defaultCardImage from "images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";

import SupportIconImage from "images/icons/mobile-app.svg";
import ShieldIconImage from "images/icons/web.svg";
import CustomizeIconImage from "images/icons/data-science 1.svg";
import FastIconImage from "images/icons/crm.svg";
import ReliableIconImage from "images/icons/prototype (1) 1.svg";
import SimpleIconImage from "images/icons/desktop-computer (1) 1.svg";
import SubheadingText from "components/UI/SubheadingText";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center mx-auto py-16 md:py-32 lg:py-8`}
`;

const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const VerticalSpacer = tw.div`mt-10 w-full flex justify-between`;

// const Column = styled.div`
//   ${tw`md:w-1/2 lg:w-1/3 max-w-sm`}
// `;
const Column = styled.div`
  ${tw`w-full md:w-1/2 lg:w-1/3 `}
`;

const Card = styled.div`
  ${tw`flex flex-col  items-center sm:items-start text-center sm:text-left w-full h-full mx-4 px-2 py-8 ml-1`}
  .imageContainer {
    ${tw`border text-center rounded-full px-5 flex items-center flex-shrink-0`}
    img {
      ${tw`w-20 h-20`}
    }
  }

  .textContainer {
    ${tw`sm:ml-4 mt-4 sm:mt-2`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-medium text-base text-primary-100 lg:text-xl xl:text-2xl leading-none`}
  }

  .description {
    ${tw`mt-1 sm:mt-4 font-medium text-black leading-loose text-justify`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default ({
  cards = null,
  heading = "Amazing Features",
  subheading = "Features",
  description,
}) => {
  const defaultCards = [
    {
      imageSrc: ShieldIconImage,
      title: "Web App Development",
      description:
        "We believe that our client's needs are tailored according to their business. To satisfy those needs, we provide from strategizing to implementation of interactive web development services. These top-notch services are customized for round the globe clients to meet their ROI expectations.",
    },
    {
      imageSrc: SupportIconImage,
      title: "Mobile App Development",
      description:
        "Our developers use the latest technologies to create the mobile app solutions for our client's custom needs. Our experts use best tools and practices to build high performance apps with easy use and pertinence. We deliver both native and cross platform mobile apps.",
    },
    {
      imageSrc: CustomizeIconImage,
      title: "Data Science",
      description:
        "We provide the service for your data. Our data scientists are at service to provide you with the best analytics techniques and deep learning to provide you with gleaned information. With our scientist you will be satisfied with the provision of data with quality.",
    },
    {
      imageSrc: ReliableIconImage,
      title: "UI/UX",
      description:
        "We provide our UI/UX services based on mapping of our client's needs. We offer tailored designs for our client's expectations and their business challenges. Our engineers and developers strive to give services perfect from experience and design.",
    },
    {
      imageSrc: FastIconImage,
      title: "CRM development",
      description:
        "We excel in CRM development, providing expert guidance to maximize the potential of our customized solutions. Our advanced infrastructure enables seamless connections and deep understanding of client needs, delivering tailored solutions for complex CRM challenges.",
    },
    {
      imageSrc: SimpleIconImage,
      title: "Desktop Software Solutions",
      description:
        "Our team consistently strives for providing the best software solutions for your desktops. Whether your project is existing or new, we are your one-stop for every desktop software solution that you can imagine.",
    },
  ];

  if (!cards) cards = defaultCards;

  return (
    <Container>
      <ThreeColumnContainer
        className="mx- items-center justify-center "
        tw="md:mx-6 lg:mx-12 items-center justify-center"
      >
        <div>
          {subheading && (
            <SubheadingText twStyle="align-middle">{subheading}</SubheadingText>
          )}
        </div>
        <Heading>{heading}</Heading>
        {description && <Description>{description}</Description>}
        <VerticalSpacer />
        <div tw="flex flex-wrap w-full justify-between ">
          {cards.map((card, i) => (
            <Column key={i}>
              <Card tw="gap-5">
                <span
                  className="imageContainer"
                  tw="items-center justify-center w-full border-none"
                >
                  <img src={card.imageSrc || defaultCardImage} alt="" />
                </span>
                <span className="textContainer">
                  <span className="title" tw="text-2xl text-black">
                    {card.title || "Fully Secure"}
                  </span>
                  <p className="description">
                    {card.description ||
                      "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud."}
                  </p>
                </span>
              </Card>
            </Column>
          ))}
        </div>
      </ThreeColumnContainer>
      {/* <DecoratorBlob /> */}
    </Container>
  );
};
