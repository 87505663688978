import tw from "twin.macro";

import AnimationRevealPage from "helpers/AnimationRevealPage";
import Header from "components/headers/light.js";
import ServicesSection from "components/service page/ServicesSection";
import Footer from "components/footers/FooterWithAddress";

// import ServiceBanner from "./../images/sample-banner.jpg";

// const Banner = tw.div`mt-[25px] h-56 flex items-center justify-center rounded-lg`;
// const BannerHeading = tw.h1`text-white text-3xl`;

export default () => {
  return (
    <AnimationRevealPage>
      <Header roundedHeaderButton={true} />
      {/* <Banner style={{ backgroundImage: `url(${ServiceBanner})`, backgroundSize: 'cover' }} className="">
        <BannerHeading>Services</BannerHeading>
      </Banner> */}

      <ServicesSection />
      <Footer />
    </AnimationRevealPage>
  );
};
