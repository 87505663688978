import React from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import { Container as ContainerBase } from 'components/misc/Layouts.js';
import logo from '../../images/whitelogo.svg';

import SocialLinks from './SocialLinks';

const Container = tw(ContainerBase)`bg-gray-900 text-gray-100 -mx-8 -mb-8`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const Row = tw.div`flex items-center justify-center flex-col px-8`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;

const LinksContainer = tw.div`font-medium flex flex-wrap justify-center items-center flex-col sm:flex-row`;
const Link = tw.a`border-b-2 border-transparent hocus:text-gray-300 hocus:border-gray-300 pb-1 transition duration-300 mt-2 mx-4`;

const CopyrightText = tw.p`text-center mt-10 font-medium tracking-wide text-sm text-gray-600`;
const Address = styled.div`
  ${tw`flex flex-col justify-center items-center w-full p-8`}
  .title {
    ${tw`text-xl font-bold`}
  }
`;
export default () => {
  return (
    <Container>
      <Content>
        <Row>
          <LogoContainer>
            <img src={logo} width={160} height={160} alt="logo" />
          </LogoContainer>
          <Address>
            <p className="title">Our Mailing Address</p>
            <p>House no 45, St 10</p>
            <p>Bahria Town, Lahore</p>
          </Address>
          <LinksContainer>
            <Link href="#">Home</Link>
            <Link href="#">About Us</Link>
            <Link href="#">Services</Link>
            <Link href="#">Case Studies</Link>
            <Link href="#">Contact Us</Link>
          </LinksContainer>
          <SocialLinks />
          <CopyrightText>&copy; Copyright 2023, Seven Stacks. All Rights Reserved.</CopyrightText>
        </Row>
      </Content>
    </Container>
  );
};
